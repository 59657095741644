var api = "https://api.qingcigame.com";
var isClaickNav = false;
var person = "";
var newsList = [];
var all_article = [];
window.onload = function () {
  // 页面初始化
  obj.init();
};
(window.onresize = function () {
  obj.resize();
}),
  // 监听页面滚动
  $(document).ready(function () {
    $(window).scroll(function () {
      if (!$(".mask_layer").is(":hidden")) {
        $(".mask_layer,.mobile_menu_bg").hide();
      }
      // 防止在公告页和公告详情页触发导致报错
      if (
        window.location.href.indexOf("/news-list.html") == -1 &&
        window.location.href.indexOf("/news-detail.html") == -1
      ) {
        var second = $(".second").offset().top;
        var third = $(".third").offset().top;
        var fourth = $(".fourth").offset().top;
        var fifth = $(".fifth").offset().top;
        var type = obj.getQueryString() && obj.getQueryString().type;
        // if ((!type || type != "admin") && !isClaickNav) {
        //   $(".nav span").removeClass("checked_bg");
        //   if ($(document).scrollTop() < fourth) {
        //     $(".nav2 span").addClass("checked_bg");
        //   } else if ($(document).scrollTop() < fifth) {
        //     $(".nav4 span").addClass("checked_bg");
        //   } else {
        //     $(".nav5 span").addClass("checked_bg");
        //   }
        // }
        if (
          !isClaickNav
          // && type == "admin"
        ) {
          $(".nav span").removeClass("checked_bg");
          if ($(document).scrollTop() < second) {
            $(".nav1 span").addClass("checked_bg");
          } else if (
            $(document).scrollTop() >= second &&
            $(document).scrollTop() < third
          ) {
            $(".nav2 span").addClass("checked_bg");
          } else if (
            $(document).scrollTop() >= third &&
            $(document).scrollTop() < fourth
          ) {
            $(".nav3 span").addClass("checked_bg");
          } else if (
            $(document).scrollTop() >= fourth &&
            $(document).scrollTop() < fifth
          ) {
            $(".nav4 span").addClass("checked_bg");
          } else if ($(document).scrollTop() >= fifth) {
            $(".nav5 span").addClass("checked_bg");
          }
        }
      }
    });
  });
var obj = {
  init: function () {
    if (window.location.href.indexOf("47.91.24.116") != -1) {
      return (window.location.href = "https://fgg.qcplay.co.jp/");
    }
    // this.indexAjax(); // 首页接口请求
    this.bind(); // 所有绑定事件
    // this.swiperInit();
    this.resize();
    this.isLogin();
    var active = obj.getQueryString().active;
    if (active) {
      $(".nav span").removeClass("checked_bg");
      isClaickNav = true;
      if (active == 1) {
        $(".nav2 span").addClass("checked_bg");
        return (
          $("html, body").animate(
            {
              scrollTop: $(".second").offset().top + "px",
            },
            {
              duration: 500,
              easing: "swing",
            }
          ),
          setTimeout(function () {
            isClaickNav = false;
          }, 600)
        );
      } else if (active == 2) {
        $(".nav3 span").addClass("checked_bg");
        return (
          $("html, body").animate(
            {
              scrollTop: $(".third").offset().top + "px",
            },
            {
              duration: 500,
              easing: "swing",
            }
          ),
          setTimeout(function () {
            isClaickNav = false;
          }, 600)
        );
      } else if (active == 3) {
        $(".nav4 span").addClass("checked_bg");
        return (
          $("html, body").animate(
            {
              scrollTop: $(".fourth").offset().top + "px",
            },
            {
              duration: 500,
              easing: "swing",
            }
          ),
          setTimeout(function () {
            isClaickNav = false;
          }, 600)
        );
      } else if (active == 4) {
        $(".nav5 span").addClass("checked_bg");
        return (
          $("html, body").animate(
            {
              scrollTop: $(".fifth").offset().top + "px",
            },
            {
              duration: 500,
              easing: "swing",
            }
          ),
          setTimeout(function () {
            isClaickNav = false;
          }, 600)
        );
      }
    }
  },
  // 防止外部用户知道url进入未公开页面
  isLogin: function () {
    var type = this.getQueryString() && this.getQueryString().type;
    var isPass = window.localStorage.getItem("isPass");
    if (type == "admin" && isPass != "1") {
      person = prompt("パスワードを入力してください:");
      if (!person || person != "123456a") {
        this.isLogin();
      } else {
        $("body").css({
          display: "block",
        });
        window.localStorage.setItem("isPass", 1);
      }
    } else {
      $("body").css({
        display: "block",
      });
    }
  },
  // 屏幕大小变化
  resize: function () {
    // 适配pc和移动端不同的样式
    var type = this.getQueryString() && this.getQueryString().type;
    var isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
    // 在移动端内
    if (isMobile) {
      var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isIOS) {
        $(".line").css({
          marginTop: "1rem",
        });
      }
      $(".footer").hide();
      $(".footer1").show();
      // 移动端资讯列表样式修改
      $(".news_list_page").css({
        height: "12.375rem",
      });
      // 下面是首页样式修改
      // 当type是admin的时候可以看到全部内容，不是admin的时候只能看到最后两部分的内容
      if (
        // (!type || type !== "admin") &&
        document.documentElement.clientWidth < 720
      ) {
        // $("header,.first,.third,.nav1,.nav3").hide();
        // $(".mobile_menu").show();
        // $(".mobile_menu_bg .nav2").addClass("check_bg mt");
        // $(".index").css({
        //   height: "calc(6420px * 0.3)",
        // });
        // $(".download").css({
        //   top: "9%",
        // });
        // $(".main").css({
        //   marginTop: "-1100px",
        // });
        // } else if (
        //   // type == "admin" &&
        //   document.documentElement.clientWidth < 720
        // ) {
        $("header").hide();
        $(".mobile_menu").show();
      }
      // 平板
      if (document.documentElement.clientWidth >= 720) {
        $("header").show();
        $("header .menu").css({
          right: 0,
        });
        $(".mobile_menu").hide();
        $(".index .logo").css({
          left: "-2rem",
        });
        $(".download").css({
          top: "-50px",
        });
        // if (!type || type !== "admin") {
        //   $(".first,.third,.nav1,.nav3").hide();
        //   $(".nav2 span").addClass("checked_bg");
        //   $("header .menu").css({
        //     width: "360px",
        //   });
        //   if (document.documentElement.clientWidth >= 1024) {
        //     $(".index").css({
        //       height: "calc(6420px * 0.33)",
        //     });
        //     $(".download").css({
        //       top: "0px",
        //     });
        //     $(".main").css({
        //       marginTop: "-900px",
        //     });
        //     if (document.documentElement.clientWidth >= 1320) {
        //       $(".index").css({
        //         height: "calc(6420px * 0.46)",
        //       });
        //       $(".main").css({
        //         marginTop: "-600px",
        //       });
        //     }
        //   } else {
        //     $(".index").css({
        //       height: "calc(6420px * 0.25)",
        //     });
        //     $(".main").css({
        //       marginTop: "-1200px",
        //     });
        //   }
        // } else
        if (
          // type == "admin" &&
          document.documentElement.clientWidth >= 1024
        ) {
          // 横板平板且显示全部内容
          $(".download").css({
            top: "0px",
          });
        }
      } else {
        $("header").hide();
        $(".mobile_menu").show();
      }
    } else {
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      if (!isMac) {
        $(".line").css({
          marginTop: "10px",
        });
      }
      // 在PC端内
      $(".mobile_menu").hide();
      // 当type是admin的时候可以看到全部内容，不是admin的时候只能看到最后两部分的内容
      // if (!type || type !== "admin") {
      //   $(".nav2 span").addClass("checked_bg");
      //   $(".first,.third,.nav1,.nav3").hide();
      //   $("header .menu").css({
      //     width: "360px",
      //   });
      //   if (document.documentElement.clientWidth > 1820) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.62)",
      //     });
      //   } else if (document.documentElement.clientWidth > 1680) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.6)",
      //     });
      //     $(".main").css({
      //       marginTop: "-100px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1600) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.57)",
      //     });
      //     $(".main").css({
      //       marginTop: "-100px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1440) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.52)",
      //     });
      //     $(".main").css({
      //       marginTop: "-350px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1366) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.5)",
      //     });
      //     $(".main").css({
      //       marginTop: "-550px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1280) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.47)",
      //     });
      //     $(".main").css({
      //       marginTop: "-600px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1200) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.42)",
      //     });
      //     $(".main").css({
      //       marginTop: "-650px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1120) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.40)",
      //     });
      //     $(".main").css({
      //       marginTop: "-700px",
      //     });
      //   } else if (document.documentElement.clientWidth > 1020) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.375)",
      //     });
      //     $(".main").css({
      //       marginTop: "-750px",
      //     });
      //   } else if (document.documentElement.clientWidth > 920) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.35)",
      //     });
      //     $(".main").css({
      //       marginTop: "-800px",
      //     });
      //   } else if (document.documentElement.clientWidth > 820) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.31)",
      //     });
      //     $(".main").css({
      //       marginTop: "-950px",
      //     });
      //   } else if (document.documentElement.clientWidth <= 820) {
      //     $(".index").css({
      //       height: "calc(6420px * 0.27)",
      //     });
      //     $(".main").css({
      //       marginTop: "-1050px",
      //     });
      //   }
      // } else {
      if (document.documentElement.clientWidth <= 656) {
        $("header .menu").css({
          transform: "scale(0.75)",
          right: "-80px",
        });
      } else if (document.documentElement.clientWidth <= 820) {
        $("header .menu").css({
          transform: "scale(0.85)",
          right: "0",
        });
      } else {
        $("header .menu").css({
          transform: "scale(1)",
          right: "60px",
        });
      }
      // }
      if (document.documentElement.clientWidth < 1020) {
        $(".footer").hide();
        $(".footer1").show();
      } else {
        $(".footer").show();
        $(".footer1").hide();
      }
    }
  },
  // 初始化接口
  indexAjax: function () {
    $.ajax({
      url: api + "/jpweb/sea/index",
      type: "GET",
      data: {
        game_id: 78,
      },
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        if (json.code == 200) {
          // 分享链接和下载链接
          for (var i = 0; i < json.data.list.ulr.length; i++) {
            $("." + json.data.list.ulr[i].get_url_name).attr(
              "href",
              json.data.list.ulr[i].get_url
            );
          }
          newsList = json.data.list.comprehensive;
          all_article = json.data.list.all_article[0];
          if (all_article.length > 0) {
            // 公告
            $.each(all_article, function (index, values) {
              var notice = `<li>
              <a href="news-detail.html?id=${values.id}">
                <div class=${
                  values.cate_id == 35
                    ? "news_type1"
                    : values.cate_id == 36
                    ? "news_type2"
                    : "news_type3"
                }></div><span class="time">${values.created_at}</span>
                <p>${values.article_title}</p>
              </a>
            </li>`;
              $(".news ul").append(notice);
            });
          }
          if (all_article.length > 0) {
            $(".more").show();
          } else {
            $(".more").hide();
          }
          // 轮播图
          // <div class="swiper_bottom"></div> 轮播图底部样式
          $.each(json.data.list.update_event, function (index, values) {
            var slideshow = `<div class="swiper-slide card${index}">
            <img src="${values.move_url}">
            <div class="swiper_top"></div>
            </div>`;
            $(".swiper-container .swiper-wrapper").append(slideshow);
          });
          if (json.data.list.update_event.length == 1) {
            obj.swiperInit(0, false);
          } else if (json.data.list.update_event.length > 0) {
            obj.swiperInit(2000, true);
          }
        } else {
          layer.msg("サーバーに接続できません", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("サーバーに接続できません", { time: 2000 });
      },
      complete: function () {},
    });
  },
  twitterShare: function () {
    // 插入推特推广
    $(".img_wrap").append(
      '<a class="twitter-timeline" href="https://twitter.com/fatgoosegym?ref_src=twsrc%5Etfw">Tweets by fatgoosegym</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
    );
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
      var _iframe = document
        .createElement("iframe")
        .contentWindow.document.body.find("a"); //get iframe下的id
      _iframe.style.opacity = 0; //修改样式
    })(document, "script", "twitter-wjs");
  },
  // 获取资讯列表
  informationListAjax: function () {
    var cate_id = obj.getQueryString().cate_id;
    var page = obj.getQueryString().page;
    $.ajax({
      url: api + "/jpweb/sea/second/page",
      type: "GET",
      data: {
        cate_id: cate_id,
        game_id: 78,
        page: page,
      },
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        if (json.code == 200) {
          var current_page = json.data.current_page; // 当前页数
          var last_page = json.data.last_page; // 总共页数
          var prev_page_url = json.data.prev_page_url; // 前一页
          var next_page_url = json.data.next_page_url; // 后一页
          var current = window.location.href.split("?")[0];
          // 公告
          $.each(json.data.data, function (index, values) {
            var notice = `
            <li>
              <a title="${values.article_title}" href="news-detail.html?id=${
              values.id
            }">
                <div class="wrap">
                  <div class=${
                    values.cate_id == 35
                      ? "news_type1"
                      : values.cate_id == 36
                      ? "news_type2"
                      : "news_type3"
                  }></div>
                  <div class="time">${values.created_at}</div>
                  <div class="title">${values.article_title}</div>
                </div>
                <p>${values.article_excerpt}</p>
              </a>
            </li>
          <hr style="border-style: dashed;border-color: rgba(0, 0, 0, 0.1);">`;
            $(".list ul").append(notice);
          });
          // 页码
          $(".pagination").html(current_page + "/" + last_page);
          if (prev_page_url) {
            $(".pre").attr(
              "href",
              current +
                "?cate_id=" +
                cate_id +
                "&page=" +
                prev_page_url.split("?page=")[1]
            );
          } else {
            $(".pre").hide();
          }
          if (next_page_url) {
            $(".next").attr(
              "href",
              current +
                "?cate_id=" +
                cate_id +
                "&page=" +
                next_page_url.split("?page=")[1]
            );
          } else {
            $(".next").hide();
          }
          var isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
          if (isMobile && document.documentElement.clientWidth < 720) {
            $(".list ul").css({
              width: "75%",
            });
            $(".list ul li").css({
              height: "50px",
            });
            $(".wrap").css({
              top: "0.5rem",
            });
            $(".news_type1,.news_type2,.news_type3").css({
              width: "2.375rem",
              height: "0.975rem",
              left: "0",
            });
            $(".wrap .time").css({
              width: "5rem",
              height: "0.975rem",
              lineHeight: "0.975rem",
              left: "3.25rem",
              "font-size": "0.75rem",
            });
            $(".wrap .title").css({
              height: "0.975rem",
              lineHeight: "0.975rem",
              left: "8rem",
              "font-size": "0.75rem",
            });
            $(".list ul li a p").css({
              marginLeft: "1.25rem",
              marginTop: "-0.75rem",
            });
            $(".page_wrap").css({
              height: "5rem",
            });
          }
        } else {
          if (json.code != 409) {
            layer.msg("サーバーに接続できません", { time: 2000 });
          }
        }
      },
      error: function () {
        layer.msg("サーバーに接続できません", { time: 2000 });
      },
      complete: function () {},
    });
  },
  // 获取新闻详情
  newsInfo: function () {
    var id = obj.getQueryString().id;
    $.ajax({
      url: api + "/jpweb/sea/content/" + id,
      data: { game_id: 78 },
      type: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        var isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
        if (isMobile && document.documentElement.clientWidth < 720) {
          $(".info_wrap .details-info").css({
            width: "70%",
          });
        }
        if (json.code == 200) {
          var newsTitle = json.data.list.article_title; // 标题
          var detailsInfo = json.data.list.article_content; // 内容
          var origin = json.data.list.origin; // 作者
          var created_at = json.data.list.created_at; // 时间
          $(".title_wrap .title").html(newsTitle);
          $(".category .origin").html(origin);
          $(".category .created_at").html(created_at);
          $(".details-info").append(detailsInfo);
          if (!origin) {
            $(".category .origin").hide();
          }
          if (!created_at) {
            $(".category .created_at").hide();
          }

          // 翻页功能
          obj.pageDown(json.data.last, json.data.next, "id");

          $("head title").html(newsTitle + "｜ぽちゃガチョ！");
          $('meta[name="keywords"]').attr(
            "content",
            json.data.list.article_keywords
          );
          $('meta[name="description"]').attr(
            "content",
            json.data.list.article_excerpt
          );
          obj.bind();
        } else {
          layer.msg("服务器请求失败", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {},
    });
  },
  // 翻页功能
  pageDown: function (lastPage, nextPage, cite) {
    var href = window.location.href.split("?")[0] + "?" + cite + "=";
    if (lastPage !== "") {
      $(".pre")
        .show()
        .attr("href", href + lastPage.id)
        .attr("title", lastPage.article_title);
    } else {
      $(".pre").hide();
    }
    if (nextPage !== "") {
      $(".next")
        .show()
        .attr("href", href + nextPage.id)
        .attr("title", nextPage.article_title);
    } else {
      $(".next").hide();
    }
  },
  // 点击事件绑定
  bind: function () {
    var cate_id = obj.getQueryString().cate_id;
    $(".category .cate").removeClass("checked_cate");
    if (cate_id) {
      $(".category .cate[cate-id=" + cate_id + "]").addClass("checked_cate");
    }
    // header导航栏点击事件
    $(".nav").click(function () {
      isClaickNav = true;
      var index = $(this).attr("data-index");
      $(".nav span").removeClass("checked_bg");
      if (index == "first") {
        $(".nav1 span").addClass("checked_bg");
      } else if (index == "second") {
        $(".nav2 span").addClass("checked_bg");
      } else if (index == "third") {
        $(".nav3 span").addClass("checked_bg");
      } else if (index == "fourth") {
        $(".nav4 span").addClass("checked_bg");
      } else if (index == "fifth") {
        $(".nav5 span").addClass("checked_bg");
      }
      return (
        $("html, body").animate(
          {
            scrollTop:
              index == "first" ? 0 : $("." + index).offset().top + "px",
          },
          {
            duration: 500,
            easing: "swing",
          }
        ),
        setTimeout(function () {
          isClaickNav = false;
        }, 600)
      );
    });
    // 移动端菜单点击事件
    $(".mobile_nav").click(function () {
      var index = $(this).attr("data-index");
      return $("html, body").animate(
        {
          scrollTop: index == "first" ? 0 : $("." + index).offset().top + "px",
        },
        {
          duration: 500,
          easing: "swing",
        }
      );
    });
    // 移动端菜单点击事件
    $(".mobile_menu").click(function () {
      $(".mask_layer,.mobile_menu_bg").show();
    });
    // 蒙版层点击事件
    $(".mask_layer").click(function () {
      $(".mask_layer,.mobile_menu_bg").hide();
    });
    // 点击介绍切换图片
    $(".story2").click(function () {
      $(".story2").toggleClass("story3");
    });
    // 切换资讯类别（首页）
    $(".news_nav a").click(function () {
      var checked_index = $(this).attr("checked-index");
      console.log(checked_index, "checked-index");
      $(".unchecked1").removeClass("checked1");
      $(".unchecked2").removeClass("checked2");
      $(".unchecked3").removeClass("checked3");
      $(".unchecked4").removeClass("checked4");
      $(".unchecked" + checked_index).addClass("checked" + checked_index);
      $(".news ul").children().remove();
      $(".more").attr(
        "href",
        checked_index == 1
          ? "news-list.html?cate_id=35&page=1"
          : checked_index == 2
          ? "news-list.html?cate_id=36&page=1"
          : checked_index == 3
          ? "news-list.html?cate_id=32&page=1"
          : "news-list.html?cate_id=0&page=1"
      );
      // 公告
      $.each(
        checked_index == 1
          ? newsList.important
          : checked_index == 2
          ? newsList.notice
          : checked_index == 3
          ? newsList.activity
          : all_article,
        function (index, values) {
          var notice = `<li>
        <a href="news-detail.html?id=${values.id}">
          <div class=${
            values.cate_id == 35
              ? "news_type1"
              : values.cate_id == 36
              ? "news_type2"
              : "news_type3"
          }></div><span class="time">${values.created_at}</span>
          <p>${values.article_title}</p>
        </a>
      </li>`;
          $(".news ul").append(notice);
        }
      );
      if (
        (newsList.important.length > 0 && checked_index == 1) ||
        (newsList.notice.length > 0 && checked_index == 2) ||
        (newsList.activity.length > 0 && checked_index == 3) ||
        (all_article.length > 0 && checked_index == 4)
      ) {
        $(".more").show();
      } else {
        $(".more").hide();
      }
    });
    // 切换咨询类别（二级页面）
    $(".title_wrap .category .cate").click(function () {
      var cate_id = $(this).attr("cate-id");
      console.log(cate_id, "cate_id");
      window.location.href =
        window.location.href.split("?")[0] + "?cate_id=" + cate_id + "&page=1";
    });
    // 回到顶部
    $(".go_home").click(function () {
      isClaickNav = true;
      $(".nav span").removeClass("checked_bg");
      $(".nav1 span").addClass("checked_bg");
      return (
        $("html, body").animate(
          {
            scrollTop: "0px",
          },
          {
            duration: 500,
            easing: "swing",
          }
        ),
        setTimeout(function () {
          isClaickNav = false;
        }, 600)
      );
    });
    // 资讯详情点击图片
    $(".details-info img").click(function () {
      var _this = $(this); //将当前的pimg元素作为_this传入函数
      obj.imgShow("#outerdiv", "#innerdiv", "#bigimg", _this);
    });
  },
  // swiper初始化
  swiperInit: function (autoplay, loop) {
    var swiper = new Swiper(".swiper-container", {
      direction: "horizontal",
      autoplay: autoplay, //可选选项，自动滑动
      loop: loop,
      pagination: ".swiper-pagination",
      prevButton: ".swiper-button-prev",
      nextButton: ".swiper-button-next",
      paginationClickable: true,
      effect: "coverflow",
      centeredSlides: true,
      slideToClickedSlide: true,
      slidesPerView: "auto",
      coverflow: {
        rotate: 10, // 旋转的角度
        stretch: 100, // 拉伸   图片间左右的间距和密集度
        depth: 140, // 深度   切换图片间上下的间距和密集度
        // modifier: 10, // 修正值 该值越大前面的效果越明显
        slideShadows: false, // 页面阴影效果
      },
      onSlideChangeStart: function (swiper) {
        var index = $(".swiper-slide-active").attr("data-swiper-slide-index");
        if (index == 0) {
          $(".card0 .swiper_top").show();
          $(
            ".card1 .swiper_top,.card2 .swiper_top,.card3 .swiper_top,.card4 .swiper_top"
          ).hide();
        } else if (index == 1) {
          $(".card1 .swiper_top").show();
          $(
            ".card0 .swiper_top,.card2 .swiper_top,.card3 .swiper_top,.card4 .swiper_top"
          ).hide();
        } else if (index == 2) {
          $(".card2 .swiper_top").show();
          $(
            ".card0 .swiper_top,.card1 .swiper_top,.card3 .swiper_top,.card4 .swiper_top"
          ).hide();
        } else if (index == 3) {
          $(".card3 .swiper_top").show();
          $(
            ".card0 .swiper_top,.card1 .swiper_top,.card2 .swiper_top,.card4 .swiper_top"
          ).hide();
        } else if (index == 4) {
          $(".card4 .swiper_top").show();
          $(
            ".card0 .swiper_top,.card1 .swiper_top,.card2 .swiper_top,.card3 .swiper_top"
          ).hide();
        }
      },
    });
  },
  // 获取查询字符串
  getQueryString: function () {
    var queryObj = {};
    var qString = window.location.href.split("?")[1];
    //undefined
    if (!qString) {
      return queryObj;
    }
    qString = qString.indexOf("#") == -1 ? qString : qString.split("#")[0];
    if (!qString) {
      return queryObj;
    }
    var qStringArr = qString.split("&");
    for (var i = 0; i < qStringArr.length; i++) {
      var key = qStringArr[i].split("=")[0];
      var value = qStringArr[i].split("=")[1];
      queryObj[key] = value;
    }
    return queryObj;
  },
  //实现的功能
  imgShow: function (outerdiv, innerdiv, bigimg, _this) {
    var src = _this.attr("src"); //获取当前点击的pimg元素中的src属性
    $(bigimg).attr("src", src); //设置#bigimg元素的src属性

    /*获取当前点击图片的真实大小，并显示弹出层及大图*/
    $("<img/>")
      .attr("src", src)
      .load(function () {
        var windowW = $(window).width(); //获取当前窗口宽度
        var windowH = $(window).height(); //获取当前窗口高度
        var realWidth = this.width; //获取图片真实宽度
        var realHeight = this.height; //获取图片真实高度
        var imgWidth, imgHeight;
        var scale = 0.8; //缩放尺寸，当图片真实宽度和高度大于窗口宽度和高度时进行缩放

        if (realHeight > windowH * scale) {
          //判断图片高度
          imgHeight = windowH * scale; //如大于窗口高度，图片高度进行缩放
          imgWidth = (imgHeight / realHeight) * realWidth; //等比例缩放宽度
          if (imgWidth > windowW * scale) {
            //如宽度扔大于窗口宽度
            imgWidth = windowW * scale; //再对宽度进行缩放
          }
        } else if (realWidth > windowW * scale) {
          //如图片高度合适，判断图片宽度
          imgWidth = windowW * scale; //如大于窗口宽度，图片宽度进行缩放
          imgHeight = (imgWidth / realWidth) * realHeight; //等比例缩放高度
        } else {
          //如果图片真实高度和宽度都符合要求，高宽不变
          imgWidth = realWidth;
          imgHeight = realHeight;
        }
        $(bigimg).css("width", imgWidth); //以最终的宽度对图片缩放

        $(outerdiv).fadeIn("fast"); //淡入显示#outerdiv及.goal_img
      });

    $(outerdiv).click(function () {
      //再次点击淡出消失弹出层
      $(this).fadeOut("fast");
    });
  },
  // faq接口请求
  faqInfo: function (id) {
    $.ajax({
      url: api + "/jpweb/consule/faq",
      data: {
        game_id: 78,
        tag_id: id,
      },
      type: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: function (json) {
        if (json.code == 200) {
          $(".faq_page .list ul").children().remove();
          // 内容
          $.each(json.data.commonProbelm, function (index, values) {
            var problem = `<li>
              <a href="javascript:;"><i></i><em></em>
                <p class="lside"><span class="comment">${values.title}</span></p>
              </a>
              <div class="hide-info">
                <p>${values.content}</p>
              </div>
          </li>`;
            $(".faq_page .list ul").append(problem);
          });
          if ($(".tag_list").children().length == 0) {
            // tab
            $.each(json.data.tag, function (index, value) {
              var tagList = `<span class='tag tag${value.id} ${
                id == value.id ? "checked_tag" : ""
              }' tag-id=${value.id}>${value.faq_tag}</span>`;
              $(".tag_list").append(tagList);
            });
          }
        } else {
          layer.msg("服务器请求失败", { time: 2000 });
        }
      },
      error: function () {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {},
    });
  },
  // faq绑定事件
  faqBind: function () {
    $(".tag_list").on("click", "span", function (event) {
      var id = $(this).attr("tag-id");
      obj.faqInfo(id);
      $(this).addClass("checked_tag").siblings().removeClass("checked_tag");
    });

    $(".faq_page .list").on("click", "a", function (event) {
      if ($(this).parent("li").hasClass("li-show")) {
        $(this).parent("li").removeClass("li-show");
      } else {
        $(this).parent("li").addClass("li-show");
      }
    });

    $(".search-btn").click(function () {
      var searchIpu = $(".search-input").val();
      if (searchIpu == "") {
        $(".tag_list span").show();
        $(".faq-result").find(".result-num").html("0");
        obj.faqInfo(8);
        return;
      }
      $.ajax({
        url: api + "/jpweb/consule/faq",
        data: {
          game_id: 78,
          title: searchIpu,
        },
        type: "GET",
        xhrFields: {
          withCredentials: true,
        },
        success: function (json) {
          if (json.code == 200) {
            $(".tag_list span").hide();
            $(".faq_page .list ul").children().remove();
            $(".faq-result")
              .find(".result-num")
              .html(json.data.commonProbelm.length);
            // 内容
            $.each(json.data.commonProbelm, function (index, values) {
              var problem = `<li>
                  <a href="javascript:;"><i></i><em></em>
                    <p class="lside"><span class="comment">${values.title}</span></p>
                  </a>
                  <div class="hide-info">
                    <p>${values.content}</p>
                  </div>
              </li>`;
              $(".faq_page .list ul").append(problem);
            });
          } else {
            layer.msg("服务器请求失败", { time: 2000 });
          }
        },
        error: function () {
          layer.msg("服务器请求失败", { time: 2000 });
        },
        complete: function () {},
      });
    });
  },
};
